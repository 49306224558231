import { Pipe, PipeTransform } from '@angular/core';
import { PermissionPipe } from '@permissions/utils';
import { UserPermissions } from '@type/user.type';

@Pipe({
  name: 'strategyPermissionFull',
  standalone: true,
  pure: false,
})
export class StrategyPermissionFullPipe extends PermissionPipe implements PipeTransform {
  permissionKey: keyof UserPermissions = 'strategyFull';
}
